import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _ from 'lodash'

import SearchSelect from '../common/searchSelect/SearchSelect'
import ManualOrderTable, { MODES as manualOrderTableModes } from './ManualOrderTable'
import { getSymbolAttributeByName, INSTRUMENT_TYPES } from '../../util/symbolUtil'
import { getPortfolioNames } from '../../util/accountUtil'
import { TRADERS } from '../../configs/config'

export const USERS = {
    MINE: 'MINE',
    ...TRADERS,
    ALL: 'ALL'
}

export const SIDES = {
    ALL: 'ALL',
    BUY: 'BUY',
    SELL: 'SELL'
}

const Tab = ({ key='', name='' }) => {
    return {
        key,
        name
    }
}

const TABS = {
    OPEN_ORDERS: Tab({
        key: 'OPEN_ORDERS',
        name: 'Open Orders'
    }),
    TRANSACTIONS: Tab({
        key: 'TRANSACTIONS',
        name: 'Transactions'
    })
}

const ALL = 'ALL'

class ManualOrderContainter extends Component {

    constructor (props) {
        super(props)
        this.state = {
            user: USERS.MINE,
            coin: ALL,
            symbolName: ALL,
            instrumentType: ALL,
            portfolio: ALL,
            accountName: ALL,
            side: SIDES.ALL,
            selectedTab: TABS.OPEN_ORDERS.key,
            coinOptions: [],
            symbolOptions: []
        }
        this.containerMainNode = null
        this.draggableLineNode = null
        this.openOrdersNode = null
        this.transactionsNode = null
    }

    componentDidMount () {
        this._updateSymbolAndCoinOptions()
    }

    componentDidUpdate (prevProps) {
        const { symbolToFilter: prevSymbolToFilter, symbolItems: prevSymbolItems } = prevProps
        const { symbolToFilter, symbolItems } = this.props
    
        const { symbolName } = this.state
        if (symbolName !== ALL && !_.isEqual(prevSymbolToFilter, symbolToFilter) 
            && symbolToFilter && !_.isNil(symbolToFilter.symbolName) && !_.isNil(symbolToFilter.id) && !_.isEqual(symbolName, symbolToFilter.symbolName)) {
            this.updateState({
                symbolName: symbolToFilter.symbolName,
                accountName: ALL
            })
        }
        if (_.size(prevSymbolItems) !== _.size(symbolItems)) {
            this._updateSymbolAndCoinOptions()
        }
    }

    updateState (newParams={}) {
        this.setState(prevState => {
            return Object.assign({}, prevState, newParams)
        })
    }

    _updateSymbolAndCoinOptions () {
        const { symbolItems } = this.props
        const filteredSymbolItems = _.filter(symbolItems, symbolItem => {
            const { instrumentType } = getSymbolAttributeByName(symbolItem.symbol_name)
            return instrumentType !== INSTRUMENT_TYPES.INDEX && symbolItem.trading === '1'
        })
        const coins = _.union(
            _.map(
                _.filter(filteredSymbolItems, symbolItem => getSymbolAttributeByName(symbolItem.symbol_name).isCompositInstrument === false),
                symbolItem => getSymbolAttributeByName(symbolItem.symbol_name).base
            )
        ).sort()
        const coinOptions = [{
            value: ALL,
            name: ALL
        }].concat(_.map(coins, coin => {
            return {
                value: coin,
                name: coin
            }
        }))

        const symbolOptions = [{
            value: ALL,
            name: ALL
        }].concat(_.map(filteredSymbolItems, symbolItem => {
            return {
                value: symbolItem.symbol_name,
                name: symbolItem.symbol_name
            }
        }))
        this.setState({
            coinOptions,
            symbolOptions
        })
    }

    handleDragLine (e) {
        let newSplitTop = ((e.clientY - this.containerMainNode.offsetTop) / (this.containerMainNode.clientHeight)) * 100
        if (newSplitTop && newSplitTop > 0 && this.openOrdersNode && this.transactionsNode && this.draggableLineNode) {
            newSplitTop = Math.max(Math.min(newSplitTop, 80), 20) + '%'
            this.openOrdersNode.style.height = newSplitTop
            this.transactionsNode.style.top = newSplitTop
            this.draggableLineNode.style.top = newSplitTop
        }
    }

    Header () {
        const { shouldUseTabs, accountItems, filterOptionPosition, onChangeSymbol } = this.props
        const { user, coin, symbolName, instrumentType, portfolio, accountName, side, selectedTab, coinOptions, symbolOptions } = this.state

        const userOptions = _.map(USERS, user => {
            return {
                value: user,
                name: user
            }
        })

        const instrumentTypeOptions = _.concat({
            value: ALL,
            name: ALL
        }, _.map(_.omit(INSTRUMENT_TYPES, ['INDEX', 'REPO', 'UNKOWN']), instrument => {
            return {
                value: instrument,
                name: instrument
            }
        }))

        const portfolioNames = getPortfolioNames()
        const portfolioOptions = _.concat([{
            value: ALL,
            name: ALL
        }], _.map(portfolioNames, portfolioName => {
            return {
                value: portfolioName,
                name: portfolioName
            }
        }))

        const accountOptions = [{
            value: ALL,
            name: ALL
        }].concat(_.sortBy(_.map(accountItems, accountItem => {
            return {
                value: accountItem.account_name,
                name: accountItem.account_name
            }
        }), 'name'))

        const sideOptions = _.map(SIDES, side => {
            return {
                value: side,
                name: side
            }
        })

        return (
            <div className='manual-order-container--header'>
                {shouldUseTabs && <div className='manual-order-container--tabs'>
                    {_.map(TABS, tab => {
                        return (
                            <button className={'manual-order-container--tabs--item' + (tab.key === selectedTab ? ' selected' : '')} 
                                key={tab.key}
                                onClick={() => {
                                   this.setState({ selectedTab: tab.key }) 
                                }}>{tab.name}</button>
                        )
                    })}
                </div>}
                <div className='manual-order-container--header--filter'>
                    <span>{'User'}</span>
                    <SearchSelect 
                        hideSearchBar
                        value={user} 
                        options={userOptions} 
                        optionPosition={filterOptionPosition || 'bottom'}
                        onChange={(newOption) => { this.setState({ user: newOption.value }) }} />
                </div>
                <div className='manual-order-container--header--filter'>
                    <span>{'Coin'}</span>
                    <SearchSelect 
                        hasClearButton
                        value={coin}
                        options={coinOptions}
                        optionPosition={filterOptionPosition || 'bottom'}
                        onChange={(newOption) => { 
                            this.setState({ 
                                coin: newOption.value,
                                symbolName: ALL
                            })
                        }} 
                        onClickClearButton={() => { 
                            this.setState({ 
                                coin: ALL
                            }) 
                        }} />
                </div>
                <div className='manual-order-container--header--filter'>
                    <span>{'Instrument Type'}</span>
                    <SearchSelect 
                        hasClearButton
                        value={instrumentType}
                        options={instrumentTypeOptions}
                        optionPosition={filterOptionPosition || 'bottom'}
                        onChange={(newOption) => { this.setState({ instrumentType: newOption.value }) }} 
                        onClickClearButton={() => { this.setState({ instrumentType: ALL }) }} />
                </div>
                <div className='manual-order-container--header--filter'>
                    <span>{'Symbol'}</span>
                    <SearchSelect 
                        hasClearButton
                        value={symbolName}
                        options={symbolOptions}
                        optionPosition={filterOptionPosition || 'bottom'}
                        onChange={(newOption) => { 
                            this.setState({ 
                                coin: coin !== ALL && !_.isEmpty(coin) && getSymbolAttributeByName(newOption.value).base !== coin ? ALL : coin,
                                symbolName: newOption.value
                            }) 
                            if (newOption.value !== ALL) {
                                onChangeSymbol(newOption.value)
                            }
                        }} 
                        onClickClearButton={() => { this.setState({ symbolName: ALL }) }} />
                </div>
                <div className='manual-order-container--header--filter'>
                    <span>{'Portfolio'}</span>
                    <SearchSelect 
                        hasClearButton
                        value={portfolio}
                        options={portfolioOptions} 
                        optionPosition={filterOptionPosition || 'bottom'}
                        onChange={(newOption) => { this.setState({ portfolio: newOption.value }) }} 
                        onClickClearButton={() => { this.setState({ portfolio: ALL }) }} />
                </div>
                <div className='manual-order-container--header--filter'>
                    <span>{'Account'}</span>
                    <SearchSelect 
                        hasClearButton
                        value={accountName}
                        options={accountOptions} 
                        optionPosition={filterOptionPosition || 'bottom'}
                        onChange={(newOption) => { this.setState({ accountName: newOption.value }) }} 
                        onClickClearButton={() => { this.setState({ accountName: ALL }) }} />
                </div>
                <div className='manual-order-container--header--filter'>
                    <span>{'Side'}</span>
                    <SearchSelect 
                        hideSearchBar
                        value={side}
                        options={sideOptions} 
                        optionPosition={filterOptionPosition || 'bottom'}
                        onChange={(newOption) => { this.setState({ side: newOption.value }) }} />
                </div>
                <button className='manual-order-container--header--reset-button' onClick={() => {
                    this.setState({
                        user: USERS.MINE,
                        coin: ALL,
                        symbolName: ALL,
                        accountName: ALL,
                        side: SIDES.ALL
                    })
                }}>{'RESET'}</button>
            </div>
        )
    }

    render () {
        const { shouldUseTabs, onChangeSymbol } = this.props
        const { user, coin, symbolName, instrumentType, portfolio, accountName, side, selectedTab } = this.state
        const tableCoinToFilter = coin !== ALL ? coin : null
        const tableSymbolNameToFilter = symbolName !== ALL ? symbolName : null
        const tableInstrumentTypeToFilter = instrumentType !== ALL ? instrumentType : null
        const tablePortfolioToFilter = portfolio !== ALL ? portfolio : null
        const tableAccountNameToFilter = accountName !== ALL ? accountName : null
        const tableSideToFilter = side !== SIDES.ALL ? side : null
        return (
            <div className='manual-order-container'>
                {this.Header()}
                <div className='manual-order-container--main' ref={(node) => { this.containerMainNode = node }}>
                    {shouldUseTabs ?  
                    <ManualOrderTable 
                        defaultMode={selectedTab === TABS.OPEN_ORDERS.key ? manualOrderTableModes.OPEN_ORDERS : manualOrderTableModes.TRANSACTIONS}
                        userToFilter={user}
                        coinToFilter={tableCoinToFilter}
                        symbolNameToFilter={tableSymbolNameToFilter}
                        instrumentTypeToFilter={tableInstrumentTypeToFilter}
                        portfolioToFilter={tablePortfolioToFilter}
                        accountNameToFilter={tableAccountNameToFilter} 
                        sideToFilter={tableSideToFilter}
                        onClickSymbolName={(newSymbolName) => { 
                            this.setState({ symbolName: newSymbolName }) 
                            onChangeSymbol(newSymbolName)
                        }} 
                        onClickAccountName={(newAccountName) => { this.setState({ accountName: newAccountName }) }} />
                    : <Fragment>
                        <div className='manual-order-container--open-orders' ref={(node) => { this.openOrdersNode = node }}>
                            <ManualOrderTable 
                                defaultMode={manualOrderTableModes.OPEN_ORDERS}
                                userToFilter={user}
                                coinToFilter={tableCoinToFilter}
                                symbolNameToFilter={tableSymbolNameToFilter}
                                instrumentTypeToFilter={tableInstrumentTypeToFilter}
                                portfolioToFilter={tablePortfolioToFilter}
                                accountNameToFilter={tableAccountNameToFilter} 
                                sideToFilter={tableSideToFilter}
                                onClickSymbolName={(newSymbolName) => { 
                                    this.setState({ symbolName: newSymbolName }) 
                                    onChangeSymbol(newSymbolName)
                                }} 
                                onClickAccountName={(newAccountName) => { this.setState({ accountName: newAccountName }) }} />
                        </div>
                        <div className='manual-order-container--draggable-line' 
                            ref={(node) => { this.draggableLineNode = node }}
                            draggable
                            onDragOver={(e) => { e.preventDefault() }}
                            onDrag={(e) => { this.handleDragLine(e) }} />
                        <div className='manual-order-container--transactions' ref={(node) => { this.transactionsNode = node }}>
                            <ManualOrderTable 
                                defaultMode={manualOrderTableModes.TRANSACTIONS}
                                userToFilter={user}
                                coinToFilter={tableCoinToFilter}
                                symbolNameToFilter={tableSymbolNameToFilter}
                                instrumentTypeToFilter={tableInstrumentTypeToFilter}
                                portfolioToFilter={tablePortfolioToFilter}
                                accountNameToFilter={tableAccountNameToFilter}
                                sideToFilter={tableSideToFilter}
                                onClickSymbolName={(newSymbolName) => { 
                                    this.setState({ symbolName: newSymbolName }) 
                                    onChangeSymbol(newSymbolName)
                                }} 
                                onClickAccountName={(newAccountName) => { this.setState({ accountName: newAccountName }) }} />
                        </div>
                    </Fragment>}
                </div>
            </div>
        )
    }
}

ManualOrderContainter.propTypes = {
    symbolItems: PropTypes.object.isRequired,
    accountItems: PropTypes.object.isRequired,
    filterOptionPosition: PropTypes.string,

    symbolToFilter: PropTypes.shape({
        symbolName: PropTypes.string,
        id: PropTypes.number
    }),
    shouldUseTabs: PropTypes.bool,
    onChangeSymbol: PropTypes.func
}

ManualOrderContainter.defaultProps = {
    filterOptionPosition: 'bottom',
    onChangeSymbol: () => {}
}

function mapStateToProps (state) {
    return {
        symbolItems: state.symbol.items,
        accountItems: state.account.items
    }
}

export default connect(mapStateToProps)(ManualOrderContainter)