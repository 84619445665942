import _ from 'lodash'
import dotProp from 'dot-prop-immutable'
import { ALL_PROFILE_GROUP_ID } from '../../configs/profileConfig'

import { UPDATE_HOSTNAME_PROFILE_STATE, ADD_PROFILE_ITEM, ADD_PROFILE_ITEMS, UPDATE_PROFILE_ITEM, UPDATE_PROFILE_ITEMS,
	ADD_PROFILE_GROUP_ITEM, UPDATE_PROFILE_GROUP_ITEM, UPDATE_PROFILE_GROUP,
	UPDATE_PROFILE_GROUP_FOCUSED_ID, UPDATE_PROFILE_GROUP_IDS, REMOVE_PROFILE_GROUP_ITEM,
	UPDATE_PROFILE_RUNNING_STATE, UPDATE_PROFILE_RUNNING_STATES, COPY_PROFILE_SUCCESS,
	UPDATE_PROFILE_SEARCH_STRING, UPDATE_PROFILE_STOPPED_UPDATING_SYMBOL_PRICINGS,
	UPDATE_PROFILE_ORDER_EDITOR_VARIABLES, UPDATE_USER_PROFILES, UPDATE_SIGNED_SWITCHED_OFF_PROFILE_SYMBOLS,
	UPDATE_SIGNAL_PROFILE, REMOVE_SIGNAL_PROFILE,
	HostnameProfileState, 
	UPDATE_SIGNAL_PROFILES, UPDATE_PROFILE_UPDATERS, UPDATE_PROFILE_UPDATER, UPDATE_PROFILE_UPDATER_LOGS, REMOVE_PROFILE_UPDATER,
	UPDATE_TWAP_STATUS_RECORDS } from './profileAction'
import { UPDATE_WEB_SOCKET_BUFFERED_PROFILE_STATE_DATA } from '../webSocket/webSocketAction'
import { AUTH_LOGOUT } from '../auth/authAction'
import { SERVERS } from '../../configs/config'
import { areAllValuesNonEmpty } from '../../util/util'

const initialState = {
	searchString: '',
	items: {},
    hostnameProfileState: _.reduce(SERVERS, (result, server) => {
        if (server.enabled) {
            result[server.hostname] = HostnameProfileState({})
        }
        return result
    }, {}),
	runningState: {},
	group: {
		focusedId: ALL_PROFILE_GROUP_ID,
		items: {
			[ALL_PROFILE_GROUP_ID]: {
				id: ALL_PROFILE_GROUP_ID,
				name: 'All Profiles',
				profileIds: [],
				editable: false
			}
		},
		ids: [ALL_PROFILE_GROUP_ID]
	},
	stoppedUpdatingSymbolPricings: [],
	userProfiles: {
		items: {},
		lastUpdateTime: null,
		lastUpdateBy: null,
		lastUpdateId: null
	},
	signedSwitchedOffProfileSymbols: {
		items: [],
		lastUpdateTime: null,
		lastUpdateBy: null,
		lastUpdateId: null
	},
	orderEditorVariables: {
		global_margin_ratio_threshold: null
	},
	signalProfile: {
		local: {},
		stored: {},
		production: {}
	},
	updaters: {},
	updaterLogs: {},
	twapStatusRecords: []
}

export function profileReducer (state = initialState, action) {
	let newState
    switch(action.type) {

        case UPDATE_HOSTNAME_PROFILE_STATE:
            return dotProp.merge(state, `hostnameProfileState.${action.hostname}`, action.params)

        case ADD_PROFILE_ITEM:
			newState = dotProp.set(state, `items.${action.profile.id}`, action.profile)
			newState.group.items[ALL_PROFILE_GROUP_ID].profileIds.unshift(action.profile.id)
			newState.group.focusedId = ALL_PROFILE_GROUP_ID
			return newState
			
		case ADD_PROFILE_ITEMS:
			newState = dotProp.merge(state, 'items', action.profiles)
			newState.group.items[ALL_PROFILE_GROUP_ID].profileIds = _.union(state.group.items[ALL_PROFILE_GROUP_ID].profileIds, Object.keys(action.profiles))
			return newState

        case UPDATE_PROFILE_ITEM:
			newState = dotProp.merge(state, `items.${action.profileId}`, action.params)
			if (!state.group.items[ALL_PROFILE_GROUP_ID].profileIds.includes(action.profileId)) {
				newState.group.items[ALL_PROFILE_GROUP_ID].profileIds.push(action.profileId)
			}
			return newState

		case UPDATE_PROFILE_ITEMS:
			newState = _.cloneDeep(state)
			_.forEach(action.profileItems, (profileItem, profileId) => {
				newState.items[profileId] = Object.assign({}, state.items[profileId] || {}, profileItem)
			})
			newState.group.items[ALL_PROFILE_GROUP_ID].profileIds = _.union(state.group.items[ALL_PROFILE_GROUP_ID].profileIds, Object.keys(action.profileItems))
			return newState

		case UPDATE_PROFILE_GROUP:
			newState = dotProp.merge(state, 'group', action.profileGroup)
			return newState

		case ADD_PROFILE_GROUP_ITEM:
			newState = dotProp.set(state, `group.items.${action.group.id}`, action.group)
			newState.group.ids.push(action.group.id)
			newState.group.focusedId = action.group.id
			return newState
			
		case UPDATE_PROFILE_GROUP_ITEM:
			newState = dotProp.merge(state, `group.items.${action.groupId}`, action.params)
			return newState

		case UPDATE_PROFILE_GROUP_FOCUSED_ID:
			newState = dotProp.set(state, 'group.focusedId', action.groupId)
			newState.searchString = ''
			return newState

		case UPDATE_PROFILE_GROUP_IDS:
			newState = dotProp.set(state, 'group.ids', action.groupIds)
			return newState

		case REMOVE_PROFILE_GROUP_ITEM:
			newState = dotProp.delete(state, `group.items.${action.groupId}`)
			_.pull(newState.group.ids, action.groupId)
			newState.group.focusedId = ALL_PROFILE_GROUP_ID
			return newState

		case UPDATE_PROFILE_RUNNING_STATE:
			return dotProp.merge(state, `runningState.${action.profileId}`, action.params)

		case UPDATE_PROFILE_RUNNING_STATES:
			newState = _.cloneDeep(state)
			_.forEach(action.runningStates, (runningState, profileId) => {
				newState.runningState[profileId] = Object.assign({}, state.runningState[profileId] || {}, runningState)
			})
			return newState

		case UPDATE_WEB_SOCKET_BUFFERED_PROFILE_STATE_DATA: {
			newState = state
			_.forEach(action.profileItems, (profileItem, profileId) => {
				const hasProfileItem = _.has(newState, `items.${profileId}`)
				newState = dotProp[hasProfileItem ? 'merge' : 'set'](newState, `items.${profileId}`, profileItem)
			})
			_.forEach(action.runningStates, (runningState, profileId) => {
				const hasRunningState = _.has(newState, `runningState.${profileId}`)
				newState = dotProp[hasRunningState ? 'merge' : 'set'](newState, `runningState.${profileId}`, runningState)
			})
			newState = dotProp.set(newState, `group.items.${ALL_PROFILE_GROUP_ID}.profileIds`, _.union(newState.group.items[ALL_PROFILE_GROUP_ID].profileIds, Object.keys(action.profileItems)))
			// console.time("test_timer1")
			// newState = _.cloneDeep(state)
			// _.forEach(action.profileItems, (profileItem, profileId) => {
			// 	newState.items[profileId] = Object.assign({}, state.items[profileId] || {}, profileItem)
			// })
			// _.forEach(action.runningStates, (runningState, profileId) => {
			// 	newState.runningState[profileId] = Object.assign({}, state.runningState[profileId] || {}, runningState)
			// })
			// newState.group.items[ALL_PROFILE_GROUP_ID].profileIds = _.union(state.group.items[ALL_PROFILE_GROUP_ID].profileIds, Object.keys(action.profileItems))
			// console.timeEnd("test_timer3")
			return newState
		}

		case COPY_PROFILE_SUCCESS:
			newState = _.cloneDeep(state)
			newState.items[action.newProfileItem.id] = Object.assign({}, state.items[action.newProfileItem.id], action.newProfileItem)

			_.pull(newState.group.items[ALL_PROFILE_GROUP_ID].profileIds, action.newProfileItem.id)
			.splice(_.findIndex(state.group.items[ALL_PROFILE_GROUP_ID].profileIds, v => v === action.originalProfileItem.id) + 1, 0, action.newProfileItem.id)

			if (state.group.focusedId !== ALL_PROFILE_GROUP_ID && state.group.items[state.group.focusedId].profileIds.includes(action.originalProfileItem.id)) {
				_.pull(newState.group.items[state.group.focusedId].profileIds, action.newProfileItem.id)
				.splice(_.findIndex(state.group.items[state.group.focusedId].profileIds, v => v === action.originalProfileItem.id) + 1, 0, action.newProfileItem.id)
			}
			return newState

		case UPDATE_PROFILE_SEARCH_STRING:
			newState = dotProp.set(state, 'searchString', action.searchString)
			if (action.shouldSwitchToAllProfileGroup) {
				newState = dotProp.set(newState, 'group.focusedId', ALL_PROFILE_GROUP_ID)
			}
			return newState

		case UPDATE_PROFILE_STOPPED_UPDATING_SYMBOL_PRICINGS:
			return dotProp.set(state, 'stoppedUpdatingSymbolPricings', action.stoppedUpdatingSymbolPricings)

		case UPDATE_PROFILE_ORDER_EDITOR_VARIABLES:
			return dotProp.merge(state, 'orderEditorVariables', action.variables)

		case UPDATE_USER_PROFILES:
			return dotProp.set(state, 'userProfiles', action.userProfiles)

		case UPDATE_SIGNED_SWITCHED_OFF_PROFILE_SYMBOLS:
			return dotProp.set(state, 'signedSwitchedOffProfileSymbols', action.signedSwitchedOffProfileSymbols)

		case UPDATE_SIGNAL_PROFILE:
			if (areAllValuesNonEmpty([action.directory, action.signalProfile?._id])) {
				const _path = `signalProfile.${action.directory}.${action.signalProfile._id}`
				if (_.has(state, _path)) {
					return dotProp.set(state, `signalProfile.${action.directory}.${action.signalProfile._id}`, action.signalProfile)
				} else {
					return dotProp.set(state, `signalProfile.${action.directory}`, {
						[action.signalProfile._id]: action.signalProfile,
						...dotProp.get(state, `signalProfile.${action.directory}`)
					})
				}
			} else {
				return state
			}

		case UPDATE_SIGNAL_PROFILES:
			if (!_.isEmpty(action.directory) && _.isArray(action.signalProfiles)) {
				return dotProp.merge(state, `signalProfile.${action.directory}`, _.keyBy(action.signalProfiles, '_id'))
			} else {
				return state
			}

		case REMOVE_SIGNAL_PROFILE:
			if (areAllValuesNonEmpty([action.directory, action.id])) {
				return dotProp.delete(state, `signalProfile.${action.directory}.${action.id}`)
			} else {
				return state
			}

		case UPDATE_PROFILE_UPDATERS:
			if (_.isObject(action.profileUpdaters)) {
				return dotProp.set(state, 'updaters', action.profileUpdaters)
			} else {
				return state
			}

		case UPDATE_PROFILE_UPDATER:
			if (_.isObject(action.profileUpdater) && !_.isEmpty(action.updaterId)) {
				return dotProp.merge(state, `updaters.${action.updaterId}`, action.profileUpdater)
			} else {
				return state
			}

		case UPDATE_PROFILE_UPDATER_LOGS:
			if (!_.isEmpty(action.updaterId) && _.isArray(action.logs)) {
				return dotProp.set(state, `updaterLogs.${action.updaterId}`, action.shouldAppend ? _.concat(state.updaterLogs[action.updaterId] || [], action.logs) : action.logs)
			} else {
				return state
			}

		case REMOVE_PROFILE_UPDATER:
			if (!_.isEmpty(action.updaterId)) {
				return dotProp.delete(state, `updaters.${action.updaterId}`)
			} else {
				return state
			}

		case UPDATE_TWAP_STATUS_RECORDS:
			return dotProp.set(state, 'twapStatusRecords', action.twapStatusRecords ?? [])

		case AUTH_LOGOUT:
			return initialState
			
        default:
            return state
    }
}