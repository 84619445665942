import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import BigNumber from 'bignumber.js'
import moment from 'moment'
import _ from 'lodash'

import { FiChevronsDown, FiChevronsUp } from 'react-icons/fi'
import { AutoSizer, Table, Column } from 'react-virtualized'

import Popup from '../common/popup/Popup'
import SearchSelect from '../common/searchSelect/SearchSelect'

import { areAllValuesNonEmpty, isMetSearchStringCriteria, toNumberWithSmartPrecision } from '../../util/util'
import { getPortfolioNames } from '../../util/accountUtil'

const ALL = 'ALL'

const GROUP_BYS = {
    EXCHANGE: 'EXCHANGE',
    COIN: 'COIN',
    ACCOUNT: 'ACCOUNT',
    PORTFOLIO: 'PORTFOLIO'
}

const GROUP_SORT_BYS = {
    AVAILABLE_SUM: 'AVAILABLE_SUM',
    EQUITY_SUM: 'EQUITY_SUM',
    NAME: 'NAME'   
}

const TABLE_ROW_TYPES = {
    AVAILABLE_BALANCE_ITEM: 'AVAILABLE_BALANCE_ITEM',
    GROUP_STATS: 'GROUP_STATS'
}

const SORT_ORDERS = {
    ASC: 'ASC',
    DESC: 'DESC'
}

const TableRow = ({ type=TABLE_ROW_TYPES.AVAILABLE_BALANCE, data={} }) => {
    return {
        type,
        data
    }
}

const AvailableBalanceGroup = ({ name='', coinTotalAmount={}, availableSumInUSD=0, equitySumInUSD=0, items=[] }) => {
    return {
        name,
        coinTotalAmount,
        availableSumInUSD,
        equitySumInUSD,
        items
    }
}

const getStoragePortfolio = () => {
    const portfolio = sessionStorage.accountAvailableBalanceTablePortfolio
    const portfolioNames = getPortfolioNames()
    return portfolioNames.includes(portfolio) ? portfolio : ALL
}

const updateStoragePortfolio = (portfolio) => {
    sessionStorage.accountAvailableBalanceTablePortfolio = portfolio
}

const getStorageGroupBy = () => {
    const groupBy = sessionStorage.accountAvailableBalanceTableGroupBy
    return _.keys(GROUP_BYS).includes(groupBy) ? groupBy : _.keys(GROUP_BYS)[0]
}

const updateStorageGroupBy = (groupBy) => {
    sessionStorage.accountAvailableBalanceTableGroupBy = groupBy
}

const getStorageGroupSortBy = () => {
    const groupSortBy = sessionStorage.accountAvailableBalanceTableGroupSortBy
    return _.keys(GROUP_SORT_BYS).includes(groupSortBy) ? groupSortBy : _.keys(GROUP_SORT_BYS)[0]
}

const updateStorageGroupSortBy = (groupSortBy) => {
    sessionStorage.accountAvailableBalanceTableGroupSortBy = groupSortBy
}

const getStorageTableSortBy = () => {
    return sessionStorage.accountAvailableBalanceTableSortBy || 'available_notional'
}

const updateStorageTableSortBy = (tableSortBy) => {
    sessionStorage.accountAvailableBalanceTableSortBy = tableSortBy
}

const getStorageTableSortOrder = () => {
    return sessionStorage.accountAvailableBalanceTableSortOrder || SORT_ORDERS.ASC
}

const updateStorageTableSortOrder = (tableSortOrder) => {
    sessionStorage.accountAvailableBalanceTableSortOrder = tableSortOrder
}

class AccountAvailableBalanceTable extends Component {
    constructor (props) {
        super(props)
        this.state = {
            portfolio: getStoragePortfolio(),
            groupBy: getStorageGroupBy(),
            groupSortBy: getStorageGroupSortBy(),
            searchString: '',
            tableSortBy: getStorageTableSortBy(),
            tableSortOrder: getStorageTableSortOrder(),
            groupNamesShouldShowItems: []
        }

        this.tableWrapperNode = null
        this.tableNode = null
        this.isScrollingTable = null
    }

    componentDidUpdate () {
        if (this.tableNode) {
            this.tableNode.recomputeRowHeights()
        }
    }

    _removeInnerScrollContainerPointerEvents () {
        if (this.tableWrapperNode && this.tableWrapperNode.ownerDocument) {
            const containers = this.tableWrapperNode.ownerDocument.getElementsByClassName('ReactVirtualized__Grid__innerScrollContainer')
            if (!_.isEmpty(containers)) {
                containers[0].style.pointerEvents = null
            }
        }
    }

    handleScrollTableMain () {
        if (window.document.hidden) {
            if (this.isScrollingTable) {
                window.clearTimeout(this.isScrollingTable)
            }
            this.isScrollingTable = setTimeout(() => {
                this._removeInnerScrollContainerPointerEvents()
            }, 66)
        }
    }

    _getGroups () {
        const { availableBalance, accountItems, spotBalances } = this.props
        const { groupBy, groupSortBy, searchString, portfolio, tableSortBy, tableSortOrder } = this.state
        const filteredAvailableBalance = _.filter(availableBalance, item => {
            const { exchange, account, acct_type, coin, portfolio_name } = item
            return (portfolio === ALL || portfolio_name === portfolio)
                && (_.isEmpty(searchString) || isMetSearchStringCriteria(`${exchange} ${account} ${acct_type} ${coin} ${portfolio_name}`, searchString))
        })

        let seivedAvailableBalance = _.sortBy(filteredAvailableBalance, item => {
            return tableSortBy === 'exchange' ? item.exchange
                : tableSortBy === 'account' ? item.account
                : tableSortBy === 'acct_type' ? item.acct_type
                : tableSortBy === 'coin' ? _.toUpper(item.coin || '')
                : tableSortBy === 'available_coin' ? -item.available_coin
                : tableSortBy === 'equity_coin' ? -item.equity_coin
                : tableSortBy === 'equity_notional' ? -item.equity_notional 
                : tableSortBy === 'portfolio_name' ? item.portfolio_name
                : tableSortBy === 'timestamp' ? moment(item.timestamp).valueOf()
                : -item.available_notional
        })

        seivedAvailableBalance = _.map(seivedAvailableBalance, item => {
            const _shouldIncludeHoldingBalance = item.acct_type === 'spot' && _.get(accountItems, `${item.account}.exchange_name`) === 'GATE'
            const _price = BigNumber(item.available_notional).div(item.available_coin).abs().toString()
            const hold = _shouldIncludeHoldingBalance ? _.get(spotBalances, `${item.account}--${_.toLower(item.coin)}.hold`) : null
            const holdingUSD = areAllValuesNonEmpty([hold, _price]) && _price !== 'NaN' ? BigNumber(hold).times(_price).toString() : null

            return {
                ...item,
                hold,
                holdingUSD
            }
        })

        if (tableSortOrder === SORT_ORDERS.DESC) {
            seivedAvailableBalance.reverse()
        }

        const rawAvailableBalanceGroups = _.groupBy(seivedAvailableBalance, item => {
            return groupBy === GROUP_BYS.COIN ? _.toUpper(item.coin || 'UNKOWN')
                : groupBy === GROUP_BYS.ACCOUNT ? item.account
                : groupBy === GROUP_BYS.PORTFOLIO ? item.portfolio_name
                : _.toUpper(item.exchange || 'UNKOWN')
        })
    
        const groups = []
        _.forEach(rawAvailableBalanceGroups, (groupItems, groupKey) => {
            const coinTotalAmount = {}
            let availableSumInUSD = 0, equitySumInUSD = 0
            _.forEach(groupItems, item => {
                const { coin, available_coin: availableCoin, available_notional: availableNotional, equity_notional: equityNotional, hold, holdingUSD } = item
                coinTotalAmount[_.toUpper(coin || 'UNKOWN')] = (coinTotalAmount[_.toUpper(coin || 'UNKOWN')] || 0) + Number(availableCoin) + Number(hold)
                availableSumInUSD += (Number(availableNotional) + Number(holdingUSD))
                equitySumInUSD += (Number(equityNotional) + Number(holdingUSD))
            })
            groups.push(AvailableBalanceGroup({
                name: groupKey,
                coinTotalAmount,
                availableSumInUSD,
                equitySumInUSD,
                items: groupItems
            }))
        })
        const sortedGroups = _.sortBy(groups, group => {
            const { name, availableSumInUSD, equitySumInUSD } = group
            return groupSortBy === GROUP_SORT_BYS.NAME ? name 
                : groupSortBy === GROUP_SORT_BYS.EQUITY_SUM ? -equitySumInUSD
                : -availableSumInUSD
        })
        return sortedGroups
    }

    _getTableRows (groups=[]) {
        const { groupNamesShouldShowItems } = this.state
        const tableRows = []
        _.forEach(groups, group => {
            const { name, coinTotalAmount, availableSumInUSD, equitySumInUSD, items } = group
            tableRows.push(TableRow({
                type: TABLE_ROW_TYPES.GROUP_STATS,
                data: {
                    name,
                    coinTotalAmount,
                    availableSumInUSD,
                    equitySumInUSD,
                    itemSize: _.size(items)
                }
            }))
            if (groupNamesShouldShowItems.includes(name)) {
                _.forEach(items, item => {
                    tableRows.push(TableRow({
                        type: TABLE_ROW_TYPES.AVAILABLE_BALANCE_ITEM,
                        data: item
                    }))
                })
            }
        })
        return tableRows
    }

    Header (groups=[]) {
        const { portfolio, groupBy, groupSortBy, groupNamesShouldShowItems, searchString } = this.state
        const groupSortByNames = {
            [GROUP_SORT_BYS.AVAILABLE_SUM]: 'Avail. SUM (USD)',
            [GROUP_SORT_BYS.EQUITY_SUM]: 'Equity SUM (USD)',
            [GROUP_SORT_BYS.NAME]: 'Name'
        }
        const portfolioNames = getPortfolioNames()
        const portfolioOptions = _.map(_.concat(ALL, portfolioNames), name => {
            return {
                value: name,
                name: name
            }
        })
        const groupByOptions = _.map(GROUP_BYS, groupBy => {
            return {
                value: groupBy,
                name: _.capitalize(groupBy)
            }
        })
        const groupSortByOptions = _.map(GROUP_SORT_BYS, groupSortBy => {
            return {
                value: groupSortBy,
                name: groupSortByNames[groupSortBy] || groupSortBy
            }
        })
        return (
            <div className='account-available-balance-table--header'>
                <div className='account-available-balance-table--header--criterias'>
                    <div className='account-available-balance-table--header--criteria-item'>
                        <span>{'Portfolio'}</span>
                        <SearchSelect 
                            hideSearchBar
                            value={portfolio}
                            options={portfolioOptions} 
                            onChange={(newOption) => { 
                                updateStoragePortfolio(newOption.value)
                                this.setState({ 
                                    portfolio: getStoragePortfolio()
                                })
                            }} />
                    </div>
                    <div className='account-available-balance-table--header--criteria-item'>
                        <span>{'Group By'}</span>
                        <SearchSelect 
                            hideSearchBar
                            value={groupBy}
                            options={groupByOptions} 
                            onChange={(newOption) => { 
                                updateStorageGroupBy(newOption.value)
                                this.setState({ 
                                    groupBy: getStorageGroupBy(),
                                    groupNamesShouldShowItems: []
                                })
                            }} />
                    </div>
                    {groupBy !== GROUP_BYS.NONE && <div className='account-available-balance-table--header--criteria-item'>
                        <span>{'Group Sort By'}</span>
                        <SearchSelect 
                            hideSearchBar
                            value={groupSortBy}
                            options={groupSortByOptions} 
                            onChange={(newOption) => { 
                                updateStorageGroupSortBy(newOption.value)
                                this.setState({ groupSortBy: getStorageGroupSortBy() })
                            }} />
                    </div>}
                </div>
                <button className='account-available-balance-table--header--toggle-group-button' onClick={() => {
                    if (!_.isEmpty(groupNamesShouldShowItems)) {
                        this.setState({ groupNamesShouldShowItems: [] })
                    } else {
                        this.setState({ groupNamesShouldShowItems: groups.map(group => group.name) })
                    }
                }}>
                    {!_.isEmpty(groupNamesShouldShowItems) ? 'Collapse All' : 'Expand All'}
                    {!_.isEmpty(groupNamesShouldShowItems) ? <FiChevronsUp /> : <FiChevronsDown />}
                </button>
                <button className='account-available-balance-table--header--reset-button'
                    onClick={() => {
                        updateStorageGroupBy(GROUP_BYS.EXCHANGE)
                        updateStorageGroupSortBy(GROUP_SORT_BYS.AVAILABLE_SUM)
                        this.setState({
                            groupBy: getStorageGroupBy(),
                            groupSortBy: getStorageGroupSortBy(),
                            searchString: '',
                            groupNamesShouldShowItems: []
                        })
                    }}>{'RESET'}</button>
                <input className='account-available-balance-table--header--search-input' 
                    placeholder={'Search Exchange, Account, Coin'}
                    spellCheck={false}
                    autoFocus={false}
                    value={searchString} 
                    onChange={(e) => { this.setState({ searchString: e.target.value }) }} />
            </div>
        )
    }

    Stats ({ availableSumInUSD=0, coinTotalAmount={}, equitySumInUSD=0 }) {
        return (
            <div className='account-available-balance-table--stats'>
                <div className='account-available-balance-table--stats--item'>
                    <label>{'Avail. SUM (USD)'}</label>
                    <span>{toNumberWithSmartPrecision({ number: availableSumInUSD, shouldApplyFloorValue: true, shouldReturnLocalString: true, defaultPrecision: 0 })}</span>
                </div>
                <div className='account-available-balance-table--stats--item' onClick={(e) => { 
                    if (e.target && e.target.className === 'account-available-balance-table--stats--coin-popup--trigger') {
                        e.stopPropagation()
                    }
                }}>
                    <label>{'Avail. Coins '}</label>
                    <Popup className='account-available-balance-table--stats--coin-popup'
                        on={'click'}
                        trigger={<span className={_.size(coinTotalAmount) > 0 ? 'account-available-balance-table--stats--coin-popup--trigger' : null}>{_.size(coinTotalAmount)}</span>}>
                        <div className='account-available-balance-table--stats--coin-popup--main' onClick={(e) => { e.stopPropagation() }}>
                            {_.map(_.keys(coinTotalAmount).sort(), coin => {
                                const amount = coinTotalAmount[coin]
                                return (
                                    <div className='account-available-balance-table--stats--coin-popup--item' key={coin}>
                                        <label>{coin}</label>
                                        <span>{toNumberWithSmartPrecision({ number: amount, shouldApplyFloorValue: true, shouldReturnLocalString: true, defaultPrecision: 2 })}</span>
                                    </div>
                                )
                            })}
                        </div>
                    </Popup>
                </div>
                <div className='account-available-balance-table--stats--item'>
                    <label>{'Equity SUM (USD)'}</label>
                    <span>{toNumberWithSmartPrecision({ number: equitySumInUSD, shouldApplyFloorValue: true, shouldReturnLocalString: true, defaultPrecision: 0 })}</span>
                </div>
            </div>
        )
    }

    GroupStatsRow (groupStatsData={}) {
        const { name, coinTotalAmount, availableSumInUSD, equitySumInUSD, itemSize } = groupStatsData
        return (
            <div className='account-available-balance-table--group-stats-row'>
                <div className='account-available-balance-table--group-stats-row--identity'>
                    <label>{name}</label>
                    <span>{` (${itemSize})`}</span>
                </div>
                <div className='account-available-balance-table--group-stats-row--stats'>
                    {this.Stats({ availableSumInUSD, coinTotalAmount, equitySumInUSD })}
                </div>
            </div>
        )
    }

    render () {
        const { tableSortBy, tableSortOrder, groupNamesShouldShowItems } = this.state
        const groups = this._getGroups()
        const tableRows = this._getTableRows(groups)
        const accumulatedCoinTotalAmount = {}
        let accumulatedAvailableSumInUSD = 0, accumulatedEquitySumInUSD = 0

        _.forEach(groups, group => {
            const { coinTotalAmount, availableSumInUSD, equitySumInUSD } = group
            _.forEach(coinTotalAmount, (amount, coin) => {
                accumulatedCoinTotalAmount[coin] = (accumulatedCoinTotalAmount[coin] || 0) + amount
            })
            accumulatedAvailableSumInUSD += availableSumInUSD
            accumulatedEquitySumInUSD += equitySumInUSD
        })
        
        return (
            <div className='account-available-balance-table' ref={(node) => { this.tableWrapperNode = node }}>
                {this.Header(groups)}
                <div className='account-available-balance-table--accumulated-stats'>
                    {this.Stats({
                        availableSumInUSD: accumulatedAvailableSumInUSD,
                        coinTotalAmount: accumulatedCoinTotalAmount,
                        equitySumInUSD: accumulatedEquitySumInUSD
                    })}
                </div>
                <div className='account-available-balance-table--main'>
                    <AutoSizer>
                        {({ width, height }) => (
                            <Table
                                ref={(node) => { this.tableNode = node }}
                                className='account-available-balance-table--table'
                                headerClassName={'account-available-balance-table--table--header'}
                                headerHeight={27}
                                width={Math.max(width, 1080)}
                                height={height}
                                rowCount={tableRows.length}
                                rowGetter={({ index }) => tableRows[index]}
                                rowClassName={({ index }) => { 
                                    let className = 'account-available-balance-table--table--row'
                                    const tableRow = tableRows[index]
                                    if (tableRow) {
                                        className += (tableRow.type === TABLE_ROW_TYPES.GROUP_STATS ? ' group-stats-row' : ' item-row')
                                        className += (index % 2 === 1 ? ' odd-row' : ' even-row')
                                    } 
                                    return className
                                }}
                                rowHeight={({ index }) => { 
                                    const tableRow = tableRows[index]
                                    return tableRow.type === TABLE_ROW_TYPES.GROUP_STATS ? 55 : 37
                                }}
                                overscanRowCount={5}
                                noRowsRenderer={() => ( <div className='account-available-balance-table--table--no-content'>{'There is no matched records.'}</div> )}
                                sort={({ sortBy: newTableSortBy }) => {
                                    const newTableSortOrder = _.isEqual(tableSortBy, newTableSortBy)
                                        ? (tableSortOrder === SORT_ORDERS.ASC ? SORT_ORDERS.DESC : SORT_ORDERS.ASC)
                                        : tableSortOrder
                                    updateStorageTableSortBy(newTableSortBy)
                                    updateStorageTableSortOrder(newTableSortOrder)
                                    this.setState({
                                        tableSortBy: getStorageTableSortBy(),
                                        tableSortOrder: getStorageTableSortOrder()
                                    })
                                }}
                                onScroll={() => { this.handleScrollTableMain() }}
                                onRowClick={({ rowData }) => {
                                    if (rowData.type === TABLE_ROW_TYPES.GROUP_STATS) {
                                        const { name } = rowData.data
                                        const newGroupsNamesShouldShowItems = groupNamesShouldShowItems.includes(name)
                                            ? _.without(groupNamesShouldShowItems, name)
                                            : _.concat(groupNamesShouldShowItems, name)
                                        this.setState({ groupNamesShouldShowItems: newGroupsNamesShouldShowItems })
                                    }
                                }}>
                                <Column dataKey={'exchange'}
                                    label={'EXCHANGE'}
                                    headerClassName={'sortable' + (tableSortBy === 'exchange' ? ' sorted' : '')}
                                    width={80}
                                    flexGrow={1}
                                    flexShrink={0}
                                    cellRenderer={({ rowData }) => {
                                        const { type, data } = rowData
                                        return type === TABLE_ROW_TYPES.GROUP_STATS
                                            ? this.GroupStatsRow(data)
                                            : data.exchange
                                    }} />
                                <Column dataKey={'account'}
                                    label={'ACCOUNT'}
                                    headerClassName={'sortable' + (tableSortBy === 'account' ? ' sorted' : '')}
                                    width={150}
                                    flexGrow={1}
                                    flexShrink={0}
                                    cellRenderer={({ rowData }) => {
                                        const { type, data } = rowData
                                        return type === TABLE_ROW_TYPES.AVAILABLE_BALANCE_ITEM
                                            ? data.account
                                            : null
                                    }} />
                                <Column dataKey={'acct_type'}
                                    label={'ACCT. TYPE'}
                                    headerClassName={'sortable' + (tableSortBy === 'acct_type' ? ' sorted' : '')}
                                    width={70}
                                    flexGrow={1}
                                    flexShrink={0}
                                    cellRenderer={({ rowData }) => {
                                        const { type, data } = rowData
                                        return type === TABLE_ROW_TYPES.AVAILABLE_BALANCE_ITEM
                                            ? data.acct_type
                                            : null
                                    }} />

                                <Column dataKey={'coin'}
                                    label={'COIN'}
                                    headerClassName={'sortable' + (tableSortBy === 'coin' ? ' sorted' : '')}
                                    width={50}
                                    flexGrow={1}
                                    flexShrink={0}
                                    cellRenderer={({ rowData }) => {
                                        const { type, data } = rowData
                                        return type === TABLE_ROW_TYPES.AVAILABLE_BALANCE_ITEM
                                            ? _.toUpper(data.coin || 'UNKOWN')
                                            : null
                                    }} />
                                <Column dataKey={'available_coin'}
                                    label={'AVAILABLE (COIN)'}
                                    className='right-align'
                                    headerClassName={'right-align sortable' + (tableSortBy === 'available_coin' ? ' sorted' : '')}
                                    width={120}
                                    flexGrow={1}
                                    flexShrink={0}
                                    cellRenderer={({ rowData }) => {
                                        const { type, data } = rowData

                                        return type === TABLE_ROW_TYPES.AVAILABLE_BALANCE_ITEM
                                            ? <>
                                                {toNumberWithSmartPrecision({ number: data.available_coin, shouldApplyFloorValue: true, shouldReturnLocalString: true, defaultPrecision: 2 })}
                                                {Number(data?.hold) > 0 &&
                                                <>
                                                    <br />
                                                    {`Hold: ${toNumberWithSmartPrecision({ number: data.hold, shouldApplyFloorValue: true, shouldReturnLocalString: true, defaultPrecision: 2 })}`}
                                                </>}
                                            </>
                                            : null
                                    }} />
                                <Column dataKey={'available_notional'}
                                    label={'AVAILABLE (USD)'}
                                    className='right-align'
                                    headerClassName={'right-align sortable' + (tableSortBy === 'available_notional' ? ' sorted' : '')}
                                    width={120}
                                    flexGrow={1}
                                    flexShrink={0}
                                    cellRenderer={({ rowData }) => {
                                        const { type, data } = rowData
                                        return type === TABLE_ROW_TYPES.AVAILABLE_BALANCE_ITEM
                                            ? <>
                                                {`$${toNumberWithSmartPrecision({ number: data.available_notional, shouldApplyFloorValue: true, shouldReturnLocalString: true, defaultPrecision: 0 })}`}
                                                {Number(data?.holdingUSD) > 0 &&
                                                <>
                                                    <br />
                                                    {`Hold: $${toNumberWithSmartPrecision({ number: data.holdingUSD, shouldApplyFloorValue: true, shouldReturnLocalString: true, defaultPrecision: 0 })}`}
                                                </>}
                                            </>
                                            : null
                                    }} />
                                <Column dataKey={'equity_coin'}
                                    label={'EQUITY (COIN)'}
                                    className='right-align'
                                    headerClassName={'right-align sortable' + (tableSortBy === 'equity_coin' ? ' sorted' : '')}
                                    width={120}
                                    flexGrow={1}
                                    flexShrink={0}
                                    cellRenderer={({ rowData }) => {
                                        const { type, data } = rowData
                                        return type === TABLE_ROW_TYPES.AVAILABLE_BALANCE_ITEM
                                            ? <>
                                                {toNumberWithSmartPrecision({ number: data.equity_coin, shouldApplyFloorValue: true, shouldReturnLocalString: true, defaultPrecision: 2 })}
                                                {Number(data?.hold) > 0 &&
                                                <>
                                                    <br />
                                                    {`Hold: ${toNumberWithSmartPrecision({ number: data.hold, shouldApplyFloorValue: true, shouldReturnLocalString: true, defaultPrecision: 2 })}`}
                                                </>}
                                            </>
                                            : null
                                    }} />
                                <Column dataKey={'equity_notional'}
                                    label={'EQUITY (USD)'}
                                    className='right-align'
                                    headerClassName={'right-align sortable' + (tableSortBy === 'equity_notional' ? ' sorted' : '')}
                                    width={120}
                                    flexGrow={1}
                                    flexShrink={0}
                                    cellRenderer={({ rowData }) => {
                                        const { type, data } = rowData
                                        return type === TABLE_ROW_TYPES.AVAILABLE_BALANCE_ITEM
                                            ? <>
                                                {`$${toNumberWithSmartPrecision({ number: data.equity_notional, shouldApplyFloorValue: true, shouldReturnLocalString: true, defaultPrecision: 0 })}`}
                                                {Number(data?.holdingUSD) > 0 &&
                                                <>
                                                    <br />
                                                    {`Hold: $${toNumberWithSmartPrecision({ number: data.holdingUSD, shouldApplyFloorValue: true, shouldReturnLocalString: true, defaultPrecision: 0 })}`}
                                                </>}
                                            </>
                                            : null
                                    }} />
                                <Column dataKey={'timestamp'}
                                    label={'TIME'}
                                    headerClassName={'sortable' + (tableSortBy === 'timestamp' ? ' sorted' : '')}
                                    width={80}
                                    flexGrow={1}
                                    flexShrink={0}
                                    cellRenderer={({ rowData }) => {
                                        const { type, data } = rowData
                                        return type === TABLE_ROW_TYPES.AVAILABLE_BALANCE_ITEM
                                            ? data.timestamp ? moment(data.timestamp).format('HH:mm:ss') : 'N/A'
                                            : null
                                    }} />
                                <Column dataKey={'portfolio_name'}
                                    label={'PORTFOLIO'}
                                    headerClassName={'sortable' + (tableSortBy === 'portfolio_name' ? ' sorted' : '')}
                                    width={80}
                                    flexGrow={1}
                                    flexShrink={0}
                                    cellRenderer={({ rowData }) => {
                                        const { type, data } = rowData
                                        return type === TABLE_ROW_TYPES.AVAILABLE_BALANCE_ITEM
                                            ? data.portfolio_name
                                            : null
                                    }} />
                            </Table>            
                        )}
                    </AutoSizer>
                </div>
            </div>
        )
    }
}

AccountAvailableBalanceTable.propTypes = {
    availableBalance: PropTypes.array.isRequired,
    spotBalances: PropTypes.object.isRequired,
    accountItems: PropTypes.object.isRequired
}

function mapStateToProps (state) {
    return {
        availableBalance: state.account.availableBalance,
        spotBalances: state.account?.balance?.spot,
        accountItems: state.account?.items
    }
}

export default connect(mapStateToProps)(AccountAvailableBalanceTable)
