import _ from 'lodash'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'

import { DEFAULT_SERVER, ELF_API_BASE_URL, getApiBaseUrlByHostname } from '../../configs/config'
import { MANUAL_ORDER_PROFILE } from '../../configs/tradingConfig'
import { secureFetch, getQueryString, areAllValuesNonEmpty } from '../../util/util'

export const ADD_NOTIFICATION_ITEMS = 'ADD_NOTIFICATION_ITEMS'
export const CLEAR_NOTIFICATION_ITEMS = 'CLEAR_NOTIFICATION_ITEMS'
export const ADD_TRANSACTION_ITEMS = 'ADD_TRANSACTION_ITEMS'
export const CLEAR_TRANSACTION_ITEMS = 'CLEAR_TRANSACTION_ITEMS'
export const UPDATE_MANUAL_ORDER_FILL = 'UPDATE_MANUAL_ORDER_FILL'
export const UPDATE_TRADING_POSITIONS = 'UPDATE_TRADING_POSITIONS'
export const UPDATE_SINGLE_LIMITS = 'UPDATE_SINGLE_LIMITS'
export const UPDATE_EXPOSURES = 'UPDATE_EXPOSURES'
export const UPDATE_EXPOSURE_MONITOR = 'UPDATE_EXPOSURE_MONITOR'
export const UPDATE_EXPOSURE_VARIABLES = 'UPDATE_EXPOSURE_VARAIBLES'
export const UPDATE_INITIAL_BALANCE = 'UPDATE_INITIAL_BALANCE'
export const UPDATE_IGNORED_ISSUE_IDS = 'UPDATE_IGNORED_ISSUE_IDS'
export const REMOVE_ISSUE_ITEM = 'REMOVE_ISSUE_ITEM'

export const UPDATE_MANUAL_ORDER = 'UPDATE_MANUAL_ORDER'
export const UPDATE_MANUAL_ORDERS = 'UPDATE_MANUAL_ORDERS'
export const REMOVE_MANUAL_ORDERS = 'REMOVE_MANUAL_ORDERS'

export const UPDATE_PROFILE_ORDER = 'UPDATE_PROFILE_ORDER'
export const UPDATE_PROFILE_ORDERS = 'UPDATE_PROFILE_ORDERS'
export const UPDATE_AUTO_TRANSFERS = 'UPDATE_AUTO_TRANSFERS'
export const UPDATE_EXPOSURE_ADJUSTMENTS = 'UPDATE_EXPOSURE_ADJUSTMENTS'
export const UPDATE_RISK_RATIO_THRESHOLDS = 'UPDATE_RISK_RATIO_THRESHOLDS'
export const UPDATE_VALUE_AT_RISK = 'UPDATE_VALUE_AT_RISK'
export const UPDATE_OPTION_GREEK = 'UPDATE_OPTION_GREEK'
export const UPDATE_NAKED_OPTION_GREEK = 'UPDATE_NAKED_OPTION_GREEK'
export const UPDATE_MM_OPTION_GREEK = 'UPDATE_MM_OPTION_GREEK'
export const UPDATE_TRADING_USERS = 'UPDATE_TRADING_USERS'
export const UPDATE_LIQUIDATION_RATIOS = 'UPDATE_LIQUIDATION_RATIOS'
export const UPDATE_LIQUIDATION_RATIO_ALERT_LIMITS = 'UPDATE_LIQUIDATION_RATIO_ALERT_LIMITS'
export const UPDATE_CREDIT_LINE_DETAILS = 'UPDATE_CREDIT_LINE_DETAILS'

export const UPDATE_INTERNAL_INVESTMENT_FLOWS = 'UPDATE_INTERNAL_INVESTMENT_FLOWS'

export const PROFILE_ORDER_STATUS = {
    SENT_TO_PF: 'SENT_TO_PF',
    PENDING: 'PENDING',
    CONFIRM: 'CONFIRM',
    REJECTED: 'REJECTED',
    PENDING_CANCEL: 'PENDING_CANCEL',
    CANCELLED: 'CANCELLED',
    FILL: 'FILL',
    PARTIAL_FILL: 'PARTIAL_FILL',
    EXPIRED: 'EXPIRED'
}

export const OrderItem = ({ client_order_id, profile, user, account_name, venue_account_name, price, first_leg_px, second_leg_px, qty, symbol, side, postonly=false, reduceonly=false, margin=false, cross_margin=false, leverage=0, option_related=false, treasury_trade=false, status, sentTimestamp, timestamp }) => {
    return { 
        client_order_id, 
        profile, 
        user, 
        account_name, 
        venue_account_name, 
        price, 
        first_leg_px,
        second_leg_px,
        qty, 
        symbol, 
        side, 
        postonly, 
        reduceonly,
        margin, 
        cross_margin,
        leverage, 
        status,
        sentTimestamp,
        option_related,
        treasury_trade,
        timestamp
    }
}

export function addNotificationItem ({id, timestamp, profileId, user, hostname, type, message, originalMessage}) {
    return (dispatch) => {
        dispatch({
            type: ADD_NOTIFICATION_ITEMS,
            notifications: [{
                id,
                timestamp,
                profileId,
                user,
                hostname,
                type,
                message,
                originalMessage
            }]
        })
    }
}

export function addNotificationItems (notifications) {
    return (dispatch) => {
        dispatch({
            type: ADD_NOTIFICATION_ITEMS,
            notifications
        })
    }
}

export function clearNotificationItems () {
    return (dispatch) => {
        dispatch({
            type: CLEAR_NOTIFICATION_ITEMS
        })
    }
}

export function addTransactionItems (transactions) {
    return (dispatch) => {
        dispatch({
            type: ADD_TRANSACTION_ITEMS,
            transactions
        })
    }
}

export function clearTransactionItems () {
    return (dispatch) => {
        dispatch({
            type: CLEAR_TRANSACTION_ITEMS
        })
    }
}

export function fetchPositions (shouldSkipStoreUpdate=false) {
    return (dispatch) => new Promise((resolve, reject) => {
        dispatch(secureFetch(`${DEFAULT_SERVER.apiBaseUrl}/position`))
        .then(response => response.json())
        .then(body => {
            if (body && _.isArray(body.info)) {
                let positions = body.info
                if (!shouldSkipStoreUpdate) {
                    dispatch({
                        type: UPDATE_TRADING_POSITIONS,
                        positions
                    })
                }
                resolve(positions)
            } else {
                throw new Error('Unexpected Return')
            }
        })
        .catch(error => {
            console.error('fetchPositions Error', error)
            reject(error)
        })
    })
}

export function fetchLiquidationRatio () {
    return (dispatch) => new Promise((resolve, reject) => {
        dispatch(secureFetch(`${ELF_API_BASE_URL}/liquidationRatio`))
        .then(response => response.json())
        .then(body => {
            if (_.isArray(body)) {
                dispatch({
                    type: UPDATE_LIQUIDATION_RATIOS,
                    liquidationRatios: _.keyBy(body, item => `${item.acct_name}--${item.product_name}`)
                })
            } else {
                throw new Error(`Unexpected return`)
            }
            resolve(body)
        })
        .catch(error => {
            console.error('fetchLiquidationRatio Error', error)
            reject(error)
        })
    })
}

export function fetchLiquidationRatioAlertLimit () {
    return (dispatch) => new Promise((resolve, reject) => {
        dispatch(secureFetch(`${ELF_API_BASE_URL}/liquidationRatioAlertLimit`))
        .then(response => response.json())
        .then(body => {
            if (_.isArray(body)) {
                dispatch({
                    type: UPDATE_LIQUIDATION_RATIO_ALERT_LIMITS,
                    alertLimits: _.keyBy(body, item => `${item.acct_name}--${item.symbol}`)
                })
            } else {
                throw new Error(`Unexpected return`)
            }
        })
        .catch(error => {
            console.error('fetchLiquidationRatioAlertLimit Error', error)
            reject(error)
        })
    })
}

export function fetchSingleLimits () {
    return (dispatch) => {
        dispatch(secureFetch(`${DEFAULT_SERVER.apiBaseUrl}/singlelimits`, {
            method: 'GET'
        })).then((response) => {
            if (response.status === 200) {
                response.json().then((body) => {
                    if (_.isArray(body.info)) {
                        dispatch({
                            type: UPDATE_SINGLE_LIMITS,
                            singleLimits: body.info
                        })
                    }
                })
            }
        })
    }
}

export function fetchFills ({ profileId, tagName, currency, symbol, portfolio, account, side, ownerUser, sortBy='fill_ts', to, pageSize=200, shouldUseManualOrderProfile=false }) {
    return (dispatch, getState) => {
        const profileItem = shouldUseManualOrderProfile ? MANUAL_ORDER_PROFILE : getState().profile.items[profileId]
        if (profileItem) {
            const queryParams = {
                profile: profileItem.name,
                hostname: profileItem.hostname,
                tagName,
                currency,
                symbol,
                portfolio,
                account,
                side,
                ownerUser,
                sortBy,
                to,
                pageSize
            }
            return dispatch(secureFetch(`${DEFAULT_SERVER.apiBaseUrl}/fill_details?${getQueryString(queryParams)}`, {
                method: 'GET'
            })).then((response) => {
                if (response.status === 200) {
                    const json = response.json()
                    return json
                }
            })
        }
    }
}

export function fetchFrontendFilledOrders ({ prodType, currency, symbol, portfolio, account, side, ownerUser, to, pageSize=200 }) {
    return (dispatch) => new Promise((resolve, reject) => {
        const queryParams = {
            prodType,
            currency,
            symbol,
            portfolio,
            account,
            side,
            ownerUser,
            to,
            pageSize
        }
        dispatch(secureFetch(`${DEFAULT_SERVER.apiBaseUrl}/frontend_filled_orders?${getQueryString(queryParams)}`))
        .then(response => response.json())
        .then(body => {
            const filledOrders = _.get(body, 'info', [])
            resolve(filledOrders)
        })
        .catch(error => {
            console.log(`fetchFrontendFilledOrders error: `, error)
            reject(error)
        })
    })
}

function _fetchExposureMonitor () {
    return (dispatch) => {
        dispatch(secureFetch(`${ELF_API_BASE_URL}/exposure-monitor`))
            .then(response => response.json())
            .then(body => {
                dispatch({
                    type: UPDATE_EXPOSURE_MONITOR,
                    exposureMonitor: body
                })
            })
            .catch(error => {
                console.error('_fetchExposureMonitor error: ', error)
            })
    }
}

export function fetchExposures () {
    return (dispatch) => {
        dispatch(secureFetch(`${ELF_API_BASE_URL}/exposures`))
            .then(response => response.json())
            .then(body => {
                if (_.isArray(body)) {
                    dispatch({
                        type: UPDATE_EXPOSURES,
                        exposures: body
                    })
                } else {
                    throw new Error('Unexpeted return')
                }
            })
            .catch(error => {
                console.error('fetchExposures error: ', error)
            })
        
        dispatch(_fetchExposureMonitor())
    }
}

export function fetchExposureById (exposureId) {
    return (dispatch) => new Promise(resolve => {
        const queryParams = {
            id: exposureId
        }
        dispatch(secureFetch(`${ELF_API_BASE_URL}/exposures?${getQueryString(queryParams)}`))
        .then(response => {
            if (response.status === 200) {
                return response.json()
            }
        })
        .then(body => resolve(body))
    })
}

export function fetchExposureTimestamps (skip=0) {
    return (dispatch) => new Promise((resolve, reject) => {
        dispatch(secureFetch(`${ELF_API_BASE_URL}/exposures/timestamps?skip=${skip}`, {
            method: 'GET'
        }))
        .then(response => {
            if (response.status === 200) {
                return response.json()
            }
        })
        .then(body => resolve(body))
        .catch(error => {
            console.error('fetchExposureTimestamps error: ', error)
            reject(error)
        })
    })
}

export function fetchExpsoureVariables () {
    return (dispatch) => new Promise(resolve => {
        dispatch(secureFetch(`${ELF_API_BASE_URL}/exposures/variables`))
        .then(response => {
            if (response.status === 200) {
                return response.json()
            }
        })
        .then(body => {
            dispatch({
                type: UPDATE_EXPOSURE_VARIABLES,
                variables: body
            })
            resolve(body)
        })
        .catch(error => {
            console.error('fetchExpsoureVariables error: ', error)
        })
    })
}

export function updateExposureVariables (variables) {
    return (dispatch) => new Promise(resolve => {
        dispatch(secureFetch(`${ELF_API_BASE_URL}/exposures/variables`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(variables)
        }))
        .then(response => resolve(response))
        .catch(error => {
            console.error('updateExposureVariables error: ', error)
        })
    })
}

export function fetchSignedExposurePreviews (skip=0) {
    return (dispatch) => new Promise(resolve => {
        dispatch(secureFetch(`${ELF_API_BASE_URL}/signedExposures/previews?${getQueryString({ skip })}`))
        .then(response => {
            if (response.status === 200) {
                return response.json()
            }
        })
        .then(body => resolve(body))
        .catch(error => {
            console.error('fetchSignedExposurePreviews error: ', error)
        })
    })
}

export function fetchSignedExposureById (exposureId) {
    return (dispatch) => new Promise(resolve => {
        const queryParams = {
            id: exposureId
        }
        dispatch(secureFetch(`${ELF_API_BASE_URL}/signedExposures?${getQueryString(queryParams)}`))
        .then(response => {
            if (response.status === 200) {
                return response.json()
            }
        })
        .then(body => resolve(body))
        .catch(error => {
            console.error('fetchSignedExposureById error: ', error)
        })
    })
}

export function signExposure ({ exposureId, user='', portfolios=[] }) {
    return (dispatch) => new Promise((resolve, reject) => {
        dispatch(secureFetch(`${ELF_API_BASE_URL}/exposures/sign`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ exposureId, user, portfolios })
        }))
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
}

export function fetchInitialBalance () {
    return (dispatch) => {
        dispatch(secureFetch(`${ELF_API_BASE_URL}/initial-balance`, {
            method: 'GET'
        }))
        .then(response => {
            if (response.status === 200) {
                return response.json()
            }
        })
        .then(body => {
            dispatch({
                type: UPDATE_INITIAL_BALANCE,
                initialBalance: _.keyBy(body, 'acct_name')
            })
        })
        .catch(error => {
            console.error('fetchInitialBalance error: ', error)
        })
    }
}

export function updateIgnoredIssueIds (ignoredIssueIds) {
    return (dispatch) => {
        dispatch({
            type: UPDATE_IGNORED_ISSUE_IDS,
            ignoredIssueIds
        })
    }
}

export function solveIssue (issueItem) {
    return (dispatch, getState) => new Promise(resolve => {
        dispatch(secureFetch(`${ELF_API_BASE_URL}/issue/solve`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                username: getState().auth.username,
                issue: issueItem
            })
        }))
        .then((response) => resolve(response))
    })
}

export function removeIssue (issueItem, solvedBy) {
    return (dispatch) => {
        const { tag, profileId, originalMessage } = issueItem
        let notificationMessage = ''
        if (['SWITCH OFF', 'QTY CAPPED'].includes(issueItem.tag) && originalMessage) {
            notificationMessage = `Account: ${originalMessage.account}, Symbol: ${originalMessage.symbol}, Side: ${originalMessage.side}`
        } else if (issueItem.tag === 'SMART ACCT EXC' && originalMessage) {
            notificationMessage = `Symbol: ${originalMessage.symbol}, Side: ${originalMessage.side}`
        }
        dispatch({
            type: REMOVE_ISSUE_ITEM,
            issueItem
        })
        dispatch(addNotificationItem({
            id: uuidv4(),
            timestamp: moment().toISOString(),
            hostname: null,
            user: solvedBy,
            type: `ISSUE SOVLED: ${tag}`,
            profileId: profileId,
            message: notificationMessage
        }))
    }
}

export function sendManualOrder (order) {
    return (dispatch, getState) => new Promise((resolve, reject) => {
        if (MANUAL_ORDER_PROFILE) {
            const orderToSend = _.omitBy(
                _.pick(order, ['client_order_id', 'profile', 'user', 'account_name', 'venue_account_name', 
                    'price', 'first_leg_px', 'second_leg_px', 'qty', 'symbol', 'side', 'postonly', 'reduceonly', 
                    'margin', 'cross_margin', 'leverage', 'option_related', 'treasury_trade']),
                _.isNil
            )
            dispatch(secureFetch(`${getApiBaseUrlByHostname(MANUAL_ORDER_PROFILE.hostname)}/order`, {
                method: 'POST',
                body: JSON.stringify(orderToSend)
            }))
            .then(response => {
                if (response.status === 200) {
                    const clientOrderId = orderToSend.client_order_id
                    const prevOrderItem = _.has(getState().trading.manualOrders, clientOrderId) 
                        ? getState().trading.manualOrders[clientOrderId]
                        : null
                    dispatch({
                        type: UPDATE_MANUAL_ORDER,
                        clientOrderId: orderToSend.client_order_id,
                        order: Object.assign({}, (prevOrderItem || orderToSend), { 
                            status: prevOrderItem ? prevOrderItem.status : PROFILE_ORDER_STATUS.SENT_TO_PF, 
                            sentTimestamp: moment().toISOString()
                        })
                    })
                    resolve(true)
                } else {
                    response.text()
                    .then(message => {
                        console.error('sendManualOrder error: ', message)
                        reject(message)
                    })
                    .catch(error => {
                        console.error('sendManualOrder error: ', error)
                        reject(`Status Code: ${response.status}`)
                    })
                }
            })
            .catch(error => {
                console.error('sendManualOrder error: ', error)
                reject(error)
            })
        }
    })
}

export function updateManualOrder ({ clientOrderId, order }) {
    return (dispatch) => {
        dispatch({
            type: UPDATE_MANUAL_ORDER,
            clientOrderId,
            order
        })
    }
}

export function updateManualOrders (orders={}) {
    return (dispatch) => {
        dispatch({
            type: UPDATE_MANUAL_ORDERS,
            orders
        })
    }
}

export function removeManulOrders (orderIds=[]) {
    return (dispatch) => {
        dispatch({
            type: REMOVE_MANUAL_ORDERS,
            orderIds
        })
    }
} 

export function updateProfileOrder ({ profileId, clientOrderId, order }) {
    return (dispatch) => {
        dispatch({
            type: UPDATE_PROFILE_ORDER,
            profileId,
            clientOrderId,
            order
        })
    }
}

export function updateProfileOrders (profileId, profileOrders) {
    return (dispatch) => {
        dispatch({
            type: UPDATE_PROFILE_ORDERS,
            profileId,
            profileOrders
        })
    }
}

export function cancelProfileOrder (profileId, clientOrderId) {
    return (dispatch, getState) => {
        const profileItem = profileId === MANUAL_ORDER_PROFILE.name ? MANUAL_ORDER_PROFILE : getState().profile.items[profileId]
        if (profileItem) {
            dispatch(secureFetch(`${getApiBaseUrlByHostname(profileItem.hostname)}/order`, {
                method: 'DELETE',
                body: JSON.stringify({
                    profile: profileItem.name,
                    user: profileItem.user,
                    client_order_id: clientOrderId
                })
            }))
        }
    }
}

export function fetchAutoTransfers (shouldFetchFromHead=true) {
    return (dispatch, getState) => new Promise((resolve, reject) => {
        const params = {
            skip: shouldFetchFromHead ? 0 : getState().trading.autoTransfers.length
        }
        dispatch(secureFetch(`${ELF_API_BASE_URL}/auto-transfers?${getQueryString(params)}`))
        .then(response => response.json())
        .then(body => {
            if (_.isArray(body)) {
                dispatch({
                    type: UPDATE_AUTO_TRANSFERS,
                    autoTransfers: body
                })
            }
            resolve(body)
        })
        .catch(error => reject(error))
    })
}

export function fetchExposureAdjustments () {
    return (dispatch) => new Promise((resolve, reject) => {
        dispatch(secureFetch(`${ELF_API_BASE_URL}/exposureAdjustments`))
        .then(response => response.json())
        .then(body => {
            if (_.isArray(body)) {
                dispatch({
                    type: UPDATE_EXPOSURE_ADJUSTMENTS,
                    exposureAdjustments: body
                })
            }
            resolve(body)
        })
        .catch(error => reject(error))
    })
}

export function createExposureAdjustment (exposureAdjustment={}) {
    return (dispatch) => new Promise((resolve, reject) => {
        dispatch(secureFetch(`${ELF_API_BASE_URL}/exposureAdjustments`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(exposureAdjustment)
        }))
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
}

export function updateExposureAdjustment (exposureAdjustment={}) {
    return (dispatch) => new Promise((resolve, reject) => {
        dispatch(secureFetch(`${ELF_API_BASE_URL}/exposureAdjustments`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(exposureAdjustment)
        }))
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
}

export function deleteExposureAdjustment (id) {
    return (dispatch) => new Promise((resolve, reject) => {
        dispatch(secureFetch(`${ELF_API_BASE_URL}/exposureAdjustments`, {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ id })
        }))
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
}

export function fetchHistoricalCoinExposures (to=null) {
    return (dispatch) => new Promise((resolve, reject) => {
        dispatch(secureFetch(`${ELF_API_BASE_URL}/historicalCoinExposures?${getQueryString({ to })}`))
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
}

export function fetchRiskRatioThresholds () {
    return (dispatch) => new Promise(resolve => {
        dispatch(secureFetch(`${ELF_API_BASE_URL}/riskRatioThresholds`))
        .then(response => response.json())
        .then(body => {
            if (body && _.isArray(body)) {
                dispatch({
                    type: UPDATE_RISK_RATIO_THRESHOLDS,
                    riskRatioThresholds: _.keyBy(body, item => !_.isEmpty(item.account) ? `${item.prod_name}--${item.account}` : item.prod_name)
                })
                resolve(body)
            } else {
                throw new Error('unexpected return')
            }
        })
        .catch(error => {
            console.error('fetchRiskRatioThresholds error: ', error)
        })
    })
}

export function fetchValueAtRisk () {
    return (dispatch) => new Promise((resolve, reject) => {
        dispatch(secureFetch(`${ELF_API_BASE_URL}/valueAtRisk`))
        .then(response => response.json())
        .then(body => {
            if (body && _.isArray(body)) {
                dispatch({
                    type: UPDATE_VALUE_AT_RISK,
                    valueAtRisk: body
                })
            }
            resolve(body)
        })
        .catch(error => {
            console.error('fetchValueAtRisk error: ', error)
            reject(error)
        })
    })
}

export function fetchOptionGreek () {
    return (dispatch) => new Promise((resolve, reject) => {
        dispatch(secureFetch(`${ELF_API_BASE_URL}/optionGreek`))
        .then(response => response.json())
        .then(body => {
            if (body && _.isArray(body)) {
                dispatch({
                    type: UPDATE_OPTION_GREEK,
                    optionGreek: _.keyBy(body, 'symbol')
                })
                resolve(body)
            } else {
                throw new Error('Unexpected return')
            }

        })
        .catch(error => {
            console.error('fetchOptionGreek error: ', error)
            reject(error)
        })
    })
}

export function fetchNakedOptionGreek () {
    return (dispatch) => new Promise((resolve, reject) => {
        dispatch(secureFetch(`${ELF_API_BASE_URL}/nakedOptionGreek`))
        .then(response => response.json())
        .then(body => {
            if (body && _.isArray(body)) {
                dispatch({
                    type: UPDATE_NAKED_OPTION_GREEK,
                    nakedOptionGreek: _.keyBy(body, 'symbol')
                })
                resolve(body)
            } else {
                throw new Error('Unexpected return')
            }
        })
        .catch(error => {
            console.error('fetchNakedOptionGreek error: ', error)
            reject(error)
        })
    })
}

export function fetchMMOptionGreek () {
    return (dispatch) => new Promise((resolve, reject) => {
        dispatch(secureFetch(`${ELF_API_BASE_URL}/mmOptionGreek`))
        .then(response => response.json())
        .then(body => {
            if (body && _.isArray(body)) {
                dispatch({
                    type: UPDATE_MM_OPTION_GREEK,
                    mmOptionGreek: _.keyBy(body, 'symbol')
                })
                resolve(body)
            } else {
                throw new Error('Unexpected return')
            }

        })
        .catch(error => {
            console.error('fetchMMOptionGreek error: ', error)
            reject(error)
        })
    })
}

export function fetchUsers () {
    return (dispatch) => {
        dispatch(secureFetch(`${DEFAULT_SERVER.apiBaseUrl}/users`))
        .then(response => response.json())
        .then(body => {
            const users = _.get(body, 'info', [])
            if (!_.isEmpty(users)) {
                dispatch({
                    type: UPDATE_TRADING_USERS,
                    users: _.keyBy(users, 'user_name')
                })
            } else {
                console.error('fetchUsers error: Empty result')
            }
        })
        .catch(error => {
            console.error(`fetchUsers error: `, error)
        })
    }
}

export function updateManualOrderFill (fillItem={}) {
    return (dispatch) => {
        if (_.has(fillItem, 'order_ref') && _.has(fillItem, 'fill_ts')) {
            const id = `${fillItem.order_ref}--${fillItem.fill_ts}`
            dispatch({
                type: UPDATE_MANUAL_ORDER_FILL,
                id,
                fillItem: Object.assign({}, fillItem, {
                    id,
                    isFillEvent: true,
                    account_name: fillItem.acct_name,
                    client_order_id: fillItem.order_ref,
                    cross_margin: fillItem.cross_margin || false,
                    leverage: fillItem.leverage || 0,
                    postonly: fillItem.postonly === '1',
                    reduceonly: fillItem.reduceonly === '1',
                    option_related: fillItem.option_related === '1',
                    treasury_trade: fillItem.treasury_trade === '1',
                    avg_fill_price: Number(fillItem.avg_fill_price),
                    price: Number(fillItem.price),
                    qty: Number(fillItem.qty),
                    last_qty: Number(fillItem.last_qty),
                    side: fillItem.side,
                    status: fillItem.status,
                    symbol: fillItem.prod_name,
                    timestamp: fillItem.fill_ts
                })
            })
        }
    }
}

export function fetchCreditLineDetails () {
    return (dispatch) => {
        dispatch(secureFetch(`${ELF_API_BASE_URL}/creditLineDetails`))
        .then(response => response.json())
        .then(body => {
            if (_.isArray(body)) {
                dispatch({
                    type: UPDATE_CREDIT_LINE_DETAILS,
                    creditLineDetails: _.keyBy(body, item => `${item.acct_name}--${_.toUpper(item.coin)}`)
                })
            } else {
                throw new Error(`Unexpected return`)
            }
        })
        .catch(error => {
            console.error(`fetchCreditLineDetails error: `, error)
        })
    }
}

export function fetchInternalInvestmentFlows () {
    return (dispatch) => new Promise((resolve, reject) => {
        dispatch(secureFetch(`${DEFAULT_SERVER.apiBaseUrl}/investment_flow_internal`))
        .then(response => response.json())
        .then(body => {
            if (_.isArray(body?.info)) {
                dispatch({
                    type: UPDATE_INTERNAL_INVESTMENT_FLOWS,
                    internalInvestmentFlows: _.keyBy(body.info, 'id')
                })
                resolve(body.info)
            }
        })
        .catch(error => {
            console.error(`getInternalInvestmentFlow error: `, error)
            reject(error)
        })
    })
}

export function createInternalInvestmentFlow ({ originPortfolio, destinationPortfolio, currency, quantity, comment }) {
    return (dispatch) => new Promise((resolve, reject) => {
        if (areAllValuesNonEmpty([originPortfolio, destinationPortfolio, currency, quantity])) {
            dispatch(secureFetch(`${_.replace(DEFAULT_SERVER.apiBaseUrl, '/v1', '/v2')}/investment_flow_internal`, {
                method: 'POST',
                body: JSON.stringify({
                    originPortfolio,
                    destinationPortfolio,
                    currency,
                    quantity,
                    comment
                })
            }))
            .then(response => {
                dispatch(fetchInternalInvestmentFlows())
                resolve(response)
            })
            .catch(error => reject(error))
        }
    })
}

export function updateExposureMonitorConfig (config={}) {
    return (dispatch, getState) => new Promise((resolve, reject) => {
        const username = _.get(getState(), 'auth.username')
        if (_.isEmpty(config) || _.isEmpty(username)) {
            console.error(`updateExposureMonitorConfig error: Object of config or username should not be empty`)
            reject(`Object of config or username should not be empty`)
        } else {
            dispatch(secureFetch(`${ELF_API_BASE_URL}/exposure-monitor-config`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    config,
                    username
                })
            }))
            .then(response => response.json())
            .then(body => {
                dispatch({
                    type: UPDATE_EXPOSURE_MONITOR,
                    exposureMonitor: body
                })
                resolve(body)
            })
            .catch (error => {
                console.error(`updateExposureMonitorConfig error: `, error)
                reject(error)
            })
        }
    })
}

export async function fetchBorrowRatePairs () {
    return async (dispatch) => {
        try {
            const response = await dispatch(secureFetch(`${ELF_API_BASE_URL}/borrow-rate/pairs`))
            const pairs = await response.json()
            return pairs
        } catch (error) {
            console.error(`fetchBorrowRatePairs error: `, error)
            throw error
        }
    }
}

export async function fetchBorrowRateHistory ({ from='', pairs=[] }) {
    return async (dispatch) => {
        try {
            const response = await dispatch(secureFetch(`${ELF_API_BASE_URL}/borrow-rate/history`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    from,
                    pairs
                })
            }))
            const borrowRates = await response.json()
            return borrowRates
        } catch (error) {
            console.error(`fetchBorrowRateHistory error: `, error)
            throw error
        }
    }
}